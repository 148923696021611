/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { arrayOf, shape, string, bool } from 'prop-types';
import { Price } from '@thd-olt-component-react/price';
import { extend } from '@thd-nucleus/data-sources';
import { CustomSkeletonLine } from './subcomponents/CustomSkeletonLine';
import { getProductsDefaultValue, updatePriceComponent, mapPriceExistsInPackage } from '../utils/product-bundle-utils';

export const BundlePrice = ({
  ...props
}) => {
  const { itemId, products } = props;

  const minAdvertisedPriceExists = mapPriceExistsInPackage(products);
  const prices = getProductsDefaultValue(products);
  if (minAdvertisedPriceExists) {
    updatePriceComponent(
      minAdvertisedPriceExists ? prices?.original : prices?.value,
      0,
      0,
      minAdvertisedPriceExists,
      prices?.value,
      itemId);
  }

  if (!products?.length) {
    return <CustomSkeletonLine height="75px" />;
  }

  return (
    <Price
      {...props}
      product={{
        pricing: {
          ...prices
        }
      }}
    />
  );
};

BundlePrice.propTypes = {
  itemId: string.isRequired,
  products: arrayOf(shape()).isRequired
};

BundlePrice.displayName = 'BundlePrice';

BundlePrice.dataModel = extend(Price);
