import { useConfigService } from '@thd-nucleus/experience-context';
import { useThdCustomer } from '@thd-olt-functional/customer-information';
import { isMajorAppliances, getMADeliveryFee } from '../utils/product-bundle-utils';

const useMajorAppliances = (product, products) => {
  const isMajorAppliance = isMajorAppliances(product);
  const { isExchangeCustomer } = useThdCustomer() || {};
  const enableApplianceDeliveryCharge = useConfigService('enableApplianceDeliveryCharge') || false;
  const enableFreeDeliveryForExchange = useConfigService('enableFreeDeliveryForExchange') || false;
  const applianceDeliveryChargeValue = useConfigService('applianceDeliveryChargeValue') || 29;

  const deliveryData = (!!products?.length && isMajorAppliance)
    ? getMADeliveryFee(
      products,
      enableApplianceDeliveryCharge,
      enableFreeDeliveryForExchange,
      applianceDeliveryChargeValue,
      isExchangeCustomer
    )
    : { deliveryFee: 0, freeShipping: false };

  return { isMajorAppliances: isMajorAppliance, deliveryData };
};

export default useMajorAppliances;
