/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { arrayOf as propTypeArrayOf, shape as propTypeShape, string as propTypeString, bool as propTypeBool } from 'prop-types';
import {
  params, string, arrayOf, number, bool, shape, extend
} from '@thd-nucleus/data-sources';
import { Typography, Alert, Tooltip } from '@one-thd/sui-atomic-components';
import { formatPrice, mapPriceExistsInPackage, getMaxQuantityLimit } from '../utils/product-bundle-utils';
import { MajorApplianceDeliveryAndTaxContent } from './subcomponents/MajorApplianceDeliveryAndTaxContent';
import { AdditionalSavingsContent } from './subcomponents/AdditionalSavingsContent';
import MinAdvertisedPriceContainer from './subcomponents/MinAdvertisedPriceContainer';
import { TOOLTIP_VIEW_DETAILS, MIN_ADVERTISED_PRICE_MESSAGE } from '../constants';
import usePriceAdjustment from '../hooks/usePriceAdjustment';
import useBundleSummaryCalculations from '../hooks/useBundleSummaryCalculations';

/**
 * Props
 *
 * @typedef {object} Props
 * @property {Product[]} products Array of Products
 * @property {String} itemId parent itemid
 * @property {object} majorApplianceData Data pertaining to Major Appliance
 */

/**
 * Properties of a bundle product
 *
 * @typedef {object} BundleProduct
 * @property {object} bundleSpecificationDetails Bundle details
 */

/**
 * Properties of a product
 *
 * @typedef {object} Product
 * @property {object} pricing The pricing of a product
 * @property {object} identifiers The identifiers of a product
 * @property {object} info The info of  a product
 */

/**
 * Returns a component, BundleSummary, showing the total price and
 * discounts applied to the bundle.
 * @param {Props} Props
 * @returns {React.ReactElement} JSX
 */

const BundleSummary = ({ products, itemId, majorApplianceData }) => {

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('bundle-summary.ready');
  }, []);

  const savingsDetail = 'The amount you save from individual items in this bundle';

  const minAdvertisedPriceExists = mapPriceExistsInPackage(products);

  const { isMajorAppliances, deliveryData } = majorApplianceData;

  const adjustedPricing = usePriceAdjustment(products, isMajorAppliances);
  const { quantityLimit } = getMaxQuantityLimit(products);

  const calculations = useBundleSummaryCalculations(itemId, adjustedPricing, minAdvertisedPriceExists, deliveryData, quantityLimit);

  if (!adjustedPricing && !calculations) {
    return null;
  }

  const suiRowClassesDash = 'sui-w-3 sui-h-1 sui-bg-primary sui-border-solid sui-border-strongest sui-border-t-1 sui-mr-8';
  const suiRowClasses = 'sui-flex sui-flex-row sui-justify-between sui-mt-2';
  const isSubtotal = true;

  return (
    <div
      className="sui-flex sui-gap-4 sui-flex-col sui-mx-4"
      data-component="BundleSummary"
      data-testid="bundle-summary"
    >
      <Typography variant="body-lg" weight="bold">Bundle Summary</Typography>
      <span>
        <div className={suiRowClasses}>
          <Typography variant="body-base">Regular Price ({calculations?.numItems} {isMajorAppliances ? 'appliances' : 'items'})</Typography>
          <MinAdvertisedPriceContainer minAdvertisedPriceExists={minAdvertisedPriceExists} dashClass={suiRowClassesDash}>
            <Typography variant="body-base" data-testid="retail-price">
              {formatPrice(calculations?.bundleSummaryPrice)}
            </Typography>
          </MinAdvertisedPriceContainer>
        </div>
        {calculations?.bundleItemSavings > 0 ? (
          <div className={suiRowClasses}>
            <span className="sui-flex sui-gap-1 sui-items-baseline">
              <span className="sui-flex sui-gap-1 sui-items-center">
                <Typography variant="body-base" color="success">Item Savings</Typography>
              </span>
              {!minAdvertisedPriceExists
                && (
                  <Tooltip offset="normal" title={savingsDetail} data-testid="bundle-savings-tooltip">
                    <Typography
                      variant="body-xs"
                      decoration="underline"
                      data-testid="bundle-view-details"
                    >
                      {TOOLTIP_VIEW_DETAILS}
                    </Typography>
                  </Tooltip>
                )}
            </span>
            <MinAdvertisedPriceContainer minAdvertisedPriceExists={minAdvertisedPriceExists} dashClass={suiRowClassesDash}>
              <Typography variant="body-base" color="success" data-testid="product-savings">
                -{formatPrice(calculations?.bundleItemSavings)}
              </Typography>
            </MinAdvertisedPriceContainer>
          </div>
        ) : null}

        <div className={suiRowClasses}>
          <Typography variant="body-base">Subtotal</Typography>
          <MinAdvertisedPriceContainer minAdvertisedPriceExists={minAdvertisedPriceExists} isSubtotal={isSubtotal} dashClass={suiRowClassesDash + ' sui-ml-auto'}>
            <span className="sui-border-solid sui-border-b-1 sui-pb-3 sui-pl-4 sui-w-18">
              <span className="sui-text-right" />
              <Typography variant="body-base" data-testid="subtotal">
                {formatPrice(calculations?.subTotal)}
              </Typography>
            </span>
          </MinAdvertisedPriceContainer>
        </div>
      </span>
      {calculations?.bundleSavings > 0 ? (
        <AdditionalSavingsContent calculations={calculations} isMajorAppliances={isMajorAppliances} />
      ) : null}

      {isMajorAppliances ? (
        <MajorApplianceDeliveryAndTaxContent deliveryData={deliveryData} />
      ) : (
        <div className={`${suiRowClasses} sui-items-center`}>
          <Typography variant="body-base">Delivery & Taxes calculated at checkout</Typography>
          <span className={suiRowClassesDash} />
        </div>
      )}
      <div className={`${suiRowClasses} sui-border-solid sui-border-t-1 sui-border-button-inactive sui-py-4`}>
        <Typography variant="body-lg" weight="bold">Total</Typography>

        <MinAdvertisedPriceContainer minAdvertisedPriceExists={minAdvertisedPriceExists} dashClass={suiRowClassesDash + ' sui-mt-5'}>
          <span className="sui-text-right">
            <Typography variant="h3" component="p" weight="bold" data-testid="finalDiscountedPrice">
              {formatPrice(calculations?.finalDiscountedPriceWithDeliveryFee)}
            </Typography>
            {calculations?.bundleSummaryPrice > calculations?.finalDiscountedPrice ? (
              <>
                <span className="sui-line-through">
                  <Typography variant="body-base" data-testid="retailPrice">
                    {formatPrice(calculations?.bundleSummaryPrice)}
                  </Typography>
                </span>
                <Typography variant="body-base" color="success" data-testid="bundle-total-dollar-off">
                  Save {formatPrice(calculations?.totalDollarOff)} <span>({Math.round(calculations?.totalPercentOff)}%)</span>
                </Typography>
              </>
            ) : null}
          </span>
        </MinAdvertisedPriceContainer>
      </div>
      {
        minAdvertisedPriceExists && (<Alert status="info">{MIN_ADVERTISED_PRICE_MESSAGE}</Alert>)
      }
    </div>
  );
};

BundleSummary.propTypes = {
  products: propTypeArrayOf(propTypeShape({
    pricing: propTypeShape()
  })).isRequired,
  itemId: propTypeString.isRequired,
  majorApplianceData: propTypeShape({
    isMajorAppliances: propTypeBool.isRequired,
    deliverData: propTypeShape()
  }).isRequired
};

BundleSummary.displayName = 'BundleSummary';

BundleSummary.dataModel = extend(
  {
    products: params({ itemIds: arrayOf(string().isRequired()).isRequired() }).arrayOf({
      pricing: params({ storeId: string() }).shape({
        original: number({ float: true }),
        mapAboveOriginalPrice: bool(),
        promotion: shape({
          type: string(),
          description: shape({
            shortDesc: string(),
            longDesc: string()
          }),
          dollarOff: number({ float: true }),
          percentageOff: number({ float: true })
        }),
        specialBuy: number({ float: true }),
        value: number({ float: true })
      }),
      identifiers: shape({
        brandName: string(),
        storeSkuNumber: string(),
        productLabel: string(),
        omsThdSku: string(),
        modelNumber: string(),
        canonicalUrl: string()
      })
    })
  }
);

export { BundleSummary };
