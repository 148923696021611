// ('event', { type: 'search', keyword: searchTerm });
export const trackReviewSummaryImpression = (isReviewSummaryDisplayed = false) => {
  if (typeof window !== 'undefined' && window.digitalData?.page?.pageInfo) {
    window.digitalData.page.pageInfo.reviewSummaryDisplayed = isReviewSummaryDisplayed;
  }
};

export const track = (key, opts) => {
  if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS) {
    window.LIFE_CYCLE_EVENT_BUS.trigger(`ratings-reviews.${key}`, opts);
  }
};

export const reportWriteAReviewFormError = (message, code) => {
  if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS) {
    window.LIFE_CYCLE_EVENT_BUS.trigger('ratings-reviews.form-error', { message, code });
  }
};
