import React from 'react';
import { DeliveryExpress } from '@one-thd/sui-icons';
import { Typography } from '@one-thd/sui-atomic-components';

export const DeliveryInfo = () => (
  <div
    data-component="DeliveryInfo"
    data-testid="delivery-info"
    className="sui-flex sui-gap-4 sui-w-full sui-pt-4"
  >
    <DeliveryExpress size="large" />
    <div>
      <Typography
        variant="body-base"
        weight="bold"
        height="tight"
      >
        Need to schedule your appliance delivery?
      </Typography>
      <Typography variant="body-base" height="tight">Select your delivery date in checkout.</Typography>
    </div>
  </div>
);