import { useState, useEffect } from 'react';

const changeSelectedItem = (productId) => {
  LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('product-bundle.productSpecificationsClicked', { itemId: productId });
};

const useSetSelectedItemId = ({ products }) => {
  const [selectedItemId, setSelectedItemId] = useState(products?.[0]?.itemId || null);

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.on('product-bundle.productSpecificationsClicked', (eventData) => {
      setSelectedItemId(eventData.output?.itemId || products?.[0]?.itemId);
    });
    return () => {
      LIFE_CYCLE_EVENT_BUS.lifeCycle.off('product-bundle.productSpecificationsClicked');
    };
  }, []);

  return {
    selectedItemId,
    changeSelectedItem
  };
};

export default useSetSelectedItemId;
